<template>
  <b-card>
    <b-modal
      ref="search-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="selectedItem.id > 0 ? $t('edit') : $t('new')"
    >
      <b-row>
        <b-col md="4">
          <g-field
            id="code"
            v-silent-search
            :value.sync="filter.code"
            label-text="code"
            name="code"
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="4">
          <g-field
            id="arabicName"
            v-silent-search
            :value.sync="filter.arabicNamePart"
            label-text="arabicName"
            name="arabicName"
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="4">
          <g-field
            v-silent-search
            :value.sync="filter.englishNamePart"
            label-text="englishName"
            name="arabicName"
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col
          cols="12"
          class="medium-window"
        >
          <b-table
            ref="itemstable"
            :items="itemsSearchProvider"
            :fields="tableColumns"
            :is-busy="isTableBusy"
            primary-key="id"
            show-empty
            striped
            hover
            responsive
            class="position-relative"
            :perPage="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :sort-direction="sortDirection"
            :filter="searchQuery"
            :filter-included-fields="filterOn"
            :empty-text="$t('noMatchingRecordsFound')"
            @filtered="onFiltered"
          >
            <template #cell(mainUnitId)="{ item }">
              <g-field
                field="select"
                item-id="unitId"
                placeholder="unit"
                class="select-size-sm"
                :value.sync="item.unitId"
                :options="item.units"
                :clearable="false"
                :label="isRight ? 'unitArabicName' : 'unitEnglishName'"
                :dir="isRight ? 'rtl' : 'ltr'"
                @change="(v) => {
                  item.price = v.salesPrice;
                  item.costPrice = v.costPrice;
                }"
              />
            </template>
            <template #head(actions)>
              <span />
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="{ item }">
              <b-button
                size="sm"
                variant="outline-primary"
                @click="
                  (val) => {
                    buttonType == 'component'
                      ? addComponentToItem(item)
                      : addAdditionToItem(item);
                  }
                "
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
          <b-row class="pt-1">
            <!-- Pagination -->
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                :label="$t('entries')"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="perPageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end py-1 pb-2"
        >
          <b-button
            class="mx-1"
            variant="secondary"
            @click="closeModal"
          >
            {{ $t('Close') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <g-form @submit="save">
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center mb-2"
        >
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  width="80"
                  :src="url"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.imgupload.$el.click()"
              >
                {{ $t('change') }}
              </b-button>
              <b-form-file
                ref="imgupload"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @change="onFileChanged"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
          <!--/ media -->
        </b-col>
      </b-row>
      <!-- static data -->
      <b-row>
        <!-- code  -->
        <b-col
          md="4"
        >
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            disabled
            label-text="code"
            name="code"
          />
        </b-col>
        <!-- arabic name  -->
        <b-col
          md="4"
        >
          <g-field
            :value.sync="selectedItem.arabicName"
            rules="required"
            label-text="arabicName"
            name="arabicName"
          />
        </b-col>
        <b-col
          md="4"
        >
          <g-field
            ref="englishName"
            :value.sync="selectedItem.englishName"
            label-text="englishName"
            name="englishName"
          />
        </b-col>
      </b-row>
      <b-tabs
        v-model="tabIndex"
        content-class="pt-1"
        pills
        fill
      >
        <b-tab :title="$t('mainData')">
          <b-card border-variant="primary">
            <b-row>
              <b-col
                md="4"
                sm="6"
                xs="12"
              >
                <h4
                  class="mb-1"
                  style="color: #7367f0;"
                >
                  <feather-icon
                    icon="ShoppingBagIcon"
                    class="mr-50"
                    size="15"
                  />
                  {{ $t('itemDetails') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                md="4"
              >
                <!-- category  -->
                <g-field
                  :value.sync="selectedItem.categoryId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  field="select"
                  :options="categories"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  label-text="category"
                />
              </b-col>
              <!-- main unit -->
              <b-col
                md="4"
              >
                <g-field
                  :value.sync="selectedItem.mainUnitId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  name="mainUnitId"
                  label-text="mainUnit"
                  field="select"
                  :options="units"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :disabled="selectedItem.isUsed === true"
                  @change="checkIfExistInUnitItems"
                />
              </b-col>
              <b-col
                md="4"
              >
                <div class="row">
                  <b-col md="9">
                    <g-field
                      v-show="selectedItem.isScalesItem === true"
                      :value.sync="selectedItem.mainUnitBarcode"
                      name="mainUnitBarcode"
                      type="number"
                      rules="required|integer"
                      label-text="barcode"
                      @input="
                        (v) => {
                          if(selectedItem.isScalesItem && v.length > 5) {
                            validateBalancedItem();
                            selectedItem.mainUnitBarcode = '';
                          } else if(selectedItem.isScalesItem && v.length < 5) {
                            codeLengthNotValid = true;
                          } else if (v.length === 5) {
                            codeLengthNotValid = false;
                          }
                        }
                      "
                    />
                    <g-field
                      v-show="selectedItem.isScalesItem === false"
                      :value.sync="selectedItem.mainUnitBarcode"
                      name="mainUnitBarcode"
                      label-text="barcode"
                      :rules="`${selectedItem.isScalesItem === false ? 'required' : 'required'}`"
                      @input="(v) => {
                        if(selectedItem.isScalesItem && v.length > 5) {
                          validateBalancedItem();
                          selectedItem.mainUnitBarcode = '';
                        } else {
                          codeLengthNotValid = false;
                        }
                      }"
                    />
                    <small
                      v-if="selectedItem.isScalesItem === true && codeLengthNotValid === true"
                      class="text-danger"
                    >{{ $t('maxLenghtIsFive') }}</small>
                  </b-col>
                  <b-col
                    md="3"
                    class="p-0"
                    style="margin-top: 27px;"
                  >
                    <b-button
                      variant="outline-primary"
                      @click="genrateCode"
                    >
                      {{ $t('createBarCode') }}
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <!-- main unit barcode -->
            </b-row>
            <b-row>
              <!-- sales price -->
              <b-col
                md="4"
              >
                <g-field
                  :value.sync="selectedItem.salesPrice"
                  name="salesPrice"
                  label-text="salesPrice"
                  type="number"
                  :rules="selectedItem.mainUnitId
                    ? `${selectedItem.maxSalesPrice > 0? `max_value:${selectedItem.maxSalesPrice}`:'min_value:0'}|required:''}`
                    : 'min_value:0'
                  "
                />
              </b-col>
              <!-- min salses price -->
              <b-col
                md="4"
              >
                <g-field
                  :value.sync="selectedItem.minSalesPrice"
                  type="number"
                  name="minSalesPrice1"
                  label-text="minSalesPrice"
                  :rules="
                    selectedItem.mainUnitId
                      ? `required|min_value:0|${selectedItem.salesPrice > 0?`max_value:${selectedItem.salesPrice}`:'min_value:0'}`
                      : 'min_value:0'
                  "
                />
              </b-col>
              <!--max salses price -->
              <b-col
                md="4"
              >
                <g-field
                  :value.sync="selectedItem.maxSalesPrice"
                  name="maxSalesPrice1"
                  label-text="maxSalesPrice"
                  type="number"
                  :rules="
                    selectedItem.mainUnitId
                      ? `required|${selectedItem.salesPrice > 0 && selectedItem.maxSalesPrice > 0?`min_value:${selectedItem.salesPrice}`:'min_value:0'}`
                      : 'min_value:0'
                  "
                />
              </b-col>
            </b-row>
            <b-row v-if="currentBranch.activityType === 'concrete' && isPerfectly === true">
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.cementTypeId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  name="cementType"
                  label-text="cementTypes"
                  field="select"
                  :options="cementTypes"
                  :label="isRight ? 'arabicName' : 'englishName'"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  id="cementContent"
                  label-text="cementContent"
                  :value.sync="selectedItem.cementContent"
                  name="cementContent"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  id="cementStrength"
                  label-text="cementStrength"
                  :value.sync="selectedItem.cementStrength"
                  name="cementStrength"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="currentBranch.activityType === 'concrete' && isPerfectly === true"
                md="4"
              >
                <!-- gravelSize  -->
                <g-field
                  id="gravelSize"
                  label-text="gravelSize"
                  :value.sync="selectedItem.gravelSize"
                  name="gravelSize"
                />
              </b-col>
              <!-- <b-col
                v-if="!this.profile.isInvoicePlan && currentBranch.activityType !== 'restaurant' && currentBranch.activityType !== 'carShowroom'"
                md="4"
                sm="12"
                class="pt-2"
              >
                <b-form-radio-group
                  v-model="selectedItem.type"
                  button-variant="outline-primary"
                  :options="dataTypesOptions"
                  class="filter-buttons"
                  buttons
                  name="radios-btn-default"
                  @change="(v) => {
                    changeType(v);
                  }"
                />
              </b-col> -->
              <!-- isStoreItem -->
              <!-- <b-col
                md="4"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="isStoredItem"
                >
                  {{ $t('isStoredItem') }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.isStoreItem"
                    class="custom-control-primary"
                  />
                </b-form-group>
              </b-col> -->
              <!-- isTaxable -->
              <b-col
                v-if="this.currentBranch.taxPolicy == 'itemBased'"
                md="2"
              >
                <b-form-group
                  class="d-inline"
                  :label="$t('isTaxable')"
                >
                  <b-form-checkbox
                    v-model="selectedItem.isTaxable"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col md="2">
                <b-form-group
                  class="d-inline"
                  :label="$t('isBalanceItem')"
                >
                  <b-form-checkbox
                    v-model="selectedItem.isScalesItem"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @change="(v) => {
                      if(v === true && selectedItem.mainUnitBarcode.length > 5) {
                        validateBalancedItem();
                        selectedItem.mainUnitBarcode = '';
                      } else {
                        codeLengthNotValid = false;
                      }
                    }"
                  />
                </b-form-group>
              </b-col> -->
              <b-col
                v-if="this.currentBranch.taxPolicy == 'itemBased'"
                :data-title="$t('itemTax')"
                :data-intro="$t('Enter the tax for the item')"
                data-step="9"
                md="4"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="itemTax"
                >
                  {{ $t('itemTax') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(9);
                      }
                    "
                  />
                </label>
                <g-field
                  :value.sync="selectedItem.itemTax"
                  name="itemTax"
                  type="number"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="4"
                sm="6"
                xs="12"
              >
                <h4
                  class="mb-1 mt-2"
                  style="color: #7367f0;"
                >
                  <feather-icon
                    icon="BoxIcon"
                    class="mr-50"
                    size="15"
                  />
                  {{ $t('itemStoresSection') }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <!-- stores -->
              <b-col
                md="4"
              >
                <b-form-group>
                  <g-field
                    v-model="store.id"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    name="stores"
                    label-text="store"
                    :options="storesData"
                    :label="isRight ? 'arabicName' : 'englishName'"
                  />
                </b-form-group>
              </b-col>

              <!-- itemPlace -->
              <b-col
                md="4"
              >
                <g-field
                  :value.sync="store.itemPlace"
                  label-text="itemPlace"
                  name="itemPlace"
                />
              </b-col>

              <!-- openingBalance -->
              <b-col
                v-if="currentBranch.activityType !== 'carShowroom' && !this.profile.isInvoicePlan"
                md="4"
              >
                <g-field
                  :value.sync="store.openingBalance"
                  :disabled="validateItem"
                  rules="required"
                  type="number"
                  label-text="openingBalance"
                />
              </b-col>

              <!-- minimumLimit -->
              <b-col
                md="4"
              >
                <g-field
                  :value.sync="store.minimumLimit"
                  rules="required"
                  type="number"
                  label-text="minimumLimit"
                />
              </b-col>
              <!-- costPrice -->
              <b-col
                v-if="!this.profile.isInvoicePlan"
                md="4"
              >
                <g-field
                  :value.sync="store.costPrice"
                  :disabled="this.profile.isAdmin === false && validatePurchItem"
                  rules="required"
                  type="number"
                  label-text="costPrice"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  variant="gradient-primary"
                  @click="addStoreToItem"
                >
                  <feather-icon
                    :icon="store.storeId > 0 ? 'EditIcon' : 'PlusSquareIcon'"
                    class="mr-50"
                  />
                  {{ store.storeId > 0 ? $t('editStore') : $t('addStore') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="medium-window mb-1 mt-1"
              >
                <g-table
                  id="storesTable"
                  ref="stores-table"
                  :items="selectedItem.stores"
                  :columns="storeTableColumns"
                  foot-clone
                  :noAction="true"
                  perPage="15"
                  :createButton="{ visiable: true, text: store.storeId > 0 ? 'editStore' : 'addStore' }"
                  :searchInput="{ visiable: false }"
                  :hide-options="true"
                  @on-create="addStoreToItem"
                >
                  <!-- Column: Actions -->
                  <template #actions="{ item }">
                    <div class="text-nowrap">
                      <b-button
                        v-b-tooltip.hover.top="$t('edit')"
                        variant="flat-secondary"
                        class="btn-icon"
                        size="sm"
                        @click="editStore(item)"
                      >
                        <feather-icon
                          :id="`invoice-row-${item.id}-preview-icon`"
                          icon="EditIcon"
                          class="clickable"
                        />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="$t('delete')"
                        variant="flat-secondary"
                        class="btn-icon"
                        size="sm"
                        @click="removeStoreFromTable(item)"
                      >
                        <feather-icon
                          :id="`invoice-row-${item.id}-delete-icon`"
                          icon="TrashIcon"
                          class="clickable"
                          stroke="red"
                        />
                      </b-button>
                    </div>
                  </template>
                </g-table>
              </b-col>
            </b-row>

            <b-row>
              <!-- notes -->
              <b-col
                md="12"
              >
                <b-form-group :label="$t('notes')">
                  <b-form-textarea
                    id="textarea"
                    v-model="selectedItem.notes"
                    label="Notes"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <!-- car details -->
        <b-tab
          v-if="currentBranch.activityType === 'carShowroom'"
          :title="$t('carsDetails')"
        >
          <b-row>
            <b-col
              cols="12"
              class="medium-window"
            >
              <g-table
                id="serialsTable"
                ref="serials-table"
                :items="selectedItem.serials"
                :columns="serialsTableColumns"
                :noAction="true"
                perPage="15"
                :createButton="{ visiable: false }"
                :searchInput="{ visiable: false }"
                :hide-options="true"
              />
            </b-col>
          </b-row>
        </b-tab>
        <!-- UNITS -->
        <b-tab
          v-if="currentBranch.activityType !== 'carShowroom'"
          :title="$t('itemUnits')"
        >
          <b-card border-variant="primary">
            <b-row>
              <b-col
                md="4"
              >
                <b-form-group>
                  <!-- main unit  -->
                  <g-field
                    :value.sync="selectedItem.mainUnitId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    :options="units"
                    label="arabicName"
                    disabled
                    label-text="mainUnit"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="4"
              >
                <!-- unit  -->
                <b-form-group>
                  <g-field
                    :value.sync="unitItem.unitId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    :options="units"
                    :label="isRight ? 'arabicName' : 'englishName'"
                    :disabled="disableSubUnit === true"
                    label-text="unit"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="4"
              >
                <!-- conversion Rate  -->
                <g-field
                  :value.sync="unitItem.conversionRate"
                  type="number"
                  :disabled="disableSubUnit === true"
                  label-text="conversionRate"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="4"
              >
                <!-- barcode  -->
                <g-field
                  :value.sync="unitItem.barcode"
                  label-text="barcode"
                />
              </b-col>
              <b-col
                md="4"
              >
                <!-- salesPrice  -->
                <g-field
                  :value.sync="unitItem.salesPrice"
                  type="number"
                  label-text="salesPrice"
                />
              </b-col>
              <b-col
                md="4"
              >
                <!-- minSalesPrice  -->
                <g-field
                  :value.sync="unitItem.minSalesPrice"
                  type="number"
                  label-text="minSalesPrice"
                />
              </b-col>
              <!-- :rules="unitItem.unitId? `required|min_value:${unitItem.salesPrice}` : ''" -->
              <b-col
                md="4"
              >
                <!-- maxSalesPrice  -->
                <g-field
                  :value.sync="unitItem.maxSalesPrice"
                  type="number"
                  label-text="maxSalesPrice"
                />
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-end mb-1"
              >
                <b-button
                  variant="gradient-primary"
                  @click="
                    () => {
                      addUnitToItem();
                    }
                  "
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    class="mr-50"
                  />
                  {{ $t('addUnit') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="medium-window"
              >
                <g-table
                  ref="items-table"
                  :items="selectedItem.subUnits"
                  :columns="unitTableColumns"
                  :noAction="true"
                  perPage="15"
                  :createButton="{ visiable: false, text: $t('addUnit') }"
                  :searchInput="{ visiable: true }"
                  :hide-options="true"
                  @on-create="
                    () => {
                      addUnitToItem();
                    }
                  "
                >
                  <template #actions="{ item }">
                    <div class="text-nowrap">
                      <feather-icon
                        :id="`invoice-row-${item.id}-trash-icon`"
                        icon="TrashIcon"
                        stroke="red"
                        class="mx-1 clickable danger"
                        @click="removeUnitFromTable(item)"
                      />
                      <b-tooltip
                        :title="$t('delete')"
                        placement="bottom"
                        :target="`invoice-row-${item.id}-Trashicon`"
                      />
                    </div>
                  </template>
                </g-table>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <!-- Components -->
        <b-tab
          v-if="currentBranch.activityType === 'restaurant' || selectedItem.type === 'perfectlyMade'"
          :title="$t('components')"
        >
          <b-card border-variant="primary">
            <b-row class="justify-content-between">
              <b-col
                cols="3"
                md="3"
              >
                <g-field
                  v-model="filter.storeId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  field="select"
                  name="stores"
                  :disabled="selectedItem.components.length > 0 || (id && selectedItem.componentsStoreId && selectedItem.components.length > 0)"
                  label-text="store"
                  :options="selectedItem.stores"
                  :label="isRight ? 'storeArabicName' : 'storeEnglishName'"
                  @change="(v) => {selectedItem.componentsStoreId = v.id}"
                />
              </b-col>
              <b-col
                cols="9"
                class="
                  d-flex
                  align-items-center
                  justify-content-end
                  mb-1 mb-md-0
                "
              >
                <b-button
                  variant="primary"
                  @click="
                    (v) => {
                      addComponentMFun();
                    }
                  "
                >
                  {{ $t('addComponents') }}
                </b-button>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col
                cols="12"
                class="medium-window"
              >
                <b-table
                  ref="items-table"
                  primary-key="id"
                  show-empty
                  striped
                  small
                  foot-clone
                  hover
                  responsive
                  class="position-relative"
                  :items="selectedItem.components"
                  :fields="componentsTableColumns"
                  :noAction="true"
                  :empty-text="$t('noMatchingRecordsFound')"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  :sort-direction="sortDirection"
                  :hide-options="true"
                >
                  <template #cell(mainUnitId)="{ item }">
                    <p class="mt-75">
                      {{ isRight ? item.unitArabicName : item.unitEnglishName }}
                    </p>
                  </template>
                  <template #cell(quantity)="{ item }">
                    <div class="text-nowrap">
                      <!-- requiredQuantity  -->
                      <g-field
                        :value.sync="item.requiredQuantity"
                        type="number"
                        size="sm"
                        class="my-0"
                        @input="(v) => {
                          item.totalCostPrice = fraction(item.requiredQuantity * item.costPrice)
                        }"
                      />
                    </div>
                  </template>
                  <template #cell(notes)="{ item }">
                    <div class="text-nowrap">
                      <!-- notes -->
                      <g-field
                        id="textarea"
                        size="sm"
                        class="my-0"
                        :value.sync="item.notes"
                        label="Notes"
                      />
                    </div>
                  </template>
                  <template #head(actions)>
                    <span />
                  </template>
                  <template #cell(actions)="{ item }">
                    <div class="text-nowrap">
                      <feather-icon
                        :id="`invoice-row-${item.id}-trash-icon`"
                        icon="TrashIcon"
                        stroke="red"
                        class="mx-1 clickable danger"
                        @click="removeComponentFromTable(item)"
                      />
                      <b-tooltip
                        :title="$t('delete')"
                        placement="bottom"
                        :target="`invoice-row-${item.id}-Trashicon`"
                      />
                    </div>
                  </template>
                  <template v-slot:foot(componentArabicName)>
                    <p />
                  </template>
                  <template v-slot:foot(mainUnitId)>
                    <p />
                  </template>
                  <template v-slot:foot(quantity)>
                    <p />
                  </template>
                  <template v-slot:foot(costPrice)>
                    <p />
                  </template>
                  <template v-slot:foot(totalCostPrice)>
                    {{ totalCosts }}
                  </template>
                  <template v-slot:foot(notes)>
                    <p />
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <!-- Additions -->
        <b-tab
          v-if="currentBranch.activityType === 'restaurant'"
          :title="$t('additions')"
        >
          <b-card border-variant="primary">
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                class="
                  d-flex
                  align-items-center
                  justify-content-end
                  mb-1 mb-md-0
                "
              >
                <b-button
                  variant="primary"
                  @click="
                    (v) => {
                      buttonType = 'addition';
                      $refs['search-modal'].show();
                    }
                  "
                >
                  {{ $t('addAdition') }}
                </b-button>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col
                cols="12"
                class="medium-window"
              >
                <g-table
                  ref="items-table"
                  :items="selectedItem.additions"
                  :columns="additionsTableColumns"
                  :noAction="true"
                  perPage="15"
                  :createButton="{ visiable: true, text: 'addAdition' }"
                  :searchInput="{ visiable: true }"
                  :hide-options="true"
                  @on-create="
                    (v) => {
                      buttonType = 'addition';
                      $refs['search-modal'].show();
                    }
                  "
                >
                  <template #actions="{ item }">
                    <div class="text-nowrap">
                      <feather-icon
                        :id="`invoice-row-${item.id}-trash-icon`"
                        icon="TrashIcon"
                        stroke="red"
                        class="mx-1 clickable danger"
                        @click="removeAdditionFromTable(item)"
                      />
                      <b-tooltip
                        :title="$t('delete')"
                        placement="bottom"
                        :target="`invoice-row-${item.id}-Trashicon`"
                      />
                    </div>
                  </template>
                </g-table>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <!-- <b-tab
          :title="$t('itemsPermmitions')"
        >
          <b-card border-variant="primary">
            <b-row>
              <b-col
                :data-title="$t('sales')"
                :data-intro="$t('sales')"
                data-step="37"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="sales"
                >
                  {{ $t('sales') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(37);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.sales"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="!this.profile.isInvoicePlan"
                :data-title="$t('purchase')"
                :data-intro="$t('purchase')"
                data-step="38"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="purchase"
                >
                  {{ $t('purchase') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(38);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.purch"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                :data-title="$t('returnSales')"
                :data-intro="$t('returnSales')"
                data-step="39"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="returnSales"
                >
                  {{ $t('returnSales') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(39);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.returnSales"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="!this.profile.isInvoicePlan"
                :data-title="$t('returnPurch')"
                :data-intro="$t('returnPurch')"
                data-step="40"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="returnPurch"
                >
                  {{ $t('returnPurch') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(40);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.returnPurch"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                :data-title="$t('quotation')"
                :data-intro="$t('quotation')"
                data-step="40"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="quotation"
                >
                  {{ $t('quotation') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(40);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.quotation"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                :data-title="$t('salesOrder')"
                :data-intro="$t('salesOrder')"
                data-step="41"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="salesOrder"
                >
                  {{ $t('salesOrder') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(41);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.salesOrder"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="!this.profile.isInvoicePlan"
                :data-title="$t('purchOrder')"
                :data-intro="$t('purchOrder')"
                data-step="41"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="purchOrder"
                >
                  {{ $t('purchOrder') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(41);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.purchOrder"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="!this.profile.isInvoicePlan"
                :data-title="$t('storageTransfer')"
                :data-intro="$t('storageTransfer')"
                data-step="41"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="storageTransfer"
                >
                  {{ $t('storageTransfer') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(41);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.storageTransfer"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!this.profile.isInvoicePlan">
              <b-col
                :data-title="$t('additionOrder')"
                :data-intro="$t('additionOrder')"
                data-step="40"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="additionOrder"
                >
                  {{ $t('additionOrder') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(40);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.additionOrder"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                :data-title="$t('exchangeOrder')"
                :data-intro="$t('sales')"
                data-step="41"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="exchangeOrder"
                >
                  {{ $t('exchangeOrder') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(41);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.exchangeOrder"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                :data-title="$t('damageAdjustment')"
                :data-intro="$t('damageAdjustment')"
                data-step="41"
                md="3"
              >
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="damageAdjustment"
                >
                  {{ $t('damageAdjustment') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('showInfo')"
                    icon="InfoIcon"
                    class="mx-25 clickable"
                    @click="
                      () => {
                        goToStep(41);
                      }
                    "
                  />
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.damageAdjustment"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab> -->
        <b-tab
          v-if="currentBranch.discountLevel === 'items'"
          :title="$t('offersAndDiscounts')"
        >
          <b-row>
            <b-col cols="5" />
            <b-col
              cols="4"
              class="pb-2"
            >
              <b-form-radio-group
                v-model="offersAndDiscountsSelected"
                button-variant="outline-primary"
                :options="offersAndDiscounts"
                buttons
                size="sm"
                name="radios-btn-default"
              />
            </b-col>
            <b-col cols="3" />
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-tabs
                card
                pills
                justified
              >
                <b-tab
                  :title="$t('offers')"
                  :disabled="offersAndDiscountsSelected == 'discounts'"
                >
                  <b-row>
                    <b-col md="4">
                      <g-picker
                        :value.sync="selectedItem.offerAppliedFromDate"
                        label-text="fromDate"
                        name="fromDate"
                      />
                    </b-col>
                    <b-col md="4">
                      <g-picker
                        :value.sync="selectedItem.offerAppliedToDate"
                        label-text="toDate"
                        name="toDate"
                      />
                    </b-col>
                    <b-col md="2">
                      <g-field
                        :value.sync="selectedItem.offerQuantity"
                        type="number"
                        placeholder="value"
                        name="value"
                        label-text="quantity"
                      />
                    </b-col>
                    <b-col md="2">
                      <g-field
                        :value.sync="selectedItem.offerFreeQuantity"
                        placeholder="freeQuantity"
                        name="freeQuantity"
                        type="number"
                        rules="min_value:0|max_value:100"
                        label-text="freeQuantity"
                      >
                        <template #append>
                          <b-input-group-text> % </b-input-group-text>
                        </template>
                      </g-field>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab
                  :title="$t('discounts')"
                  :disabled="offersAndDiscountsSelected == 'offers'"
                >
                  <b-row>
                    <b-col md="4">
                      <g-picker
                        :value.sync="selectedItem.discountAppliedFromDate"
                        label-text="fromDate"
                        name="fromDate"
                      />
                    </b-col>
                    <b-col md="4">
                      <g-picker
                        :value.sync="selectedItem.discountAppliedToDate"
                        label-text="toDate"
                        name="toDate"
                      />
                    </b-col>
                    <b-col md="2">
                      <g-field
                        id="discount"
                        :value.sync="selectedItem.discountValue"
                        :disabled="selectedItem.discountPercentage > 0"
                        type="number"
                        placeholder="value"
                        name="value"
                        label-text="discountValue"
                        @change="() => {
                          selectedItem.discountPercentage = 0
                        }"
                      />
                    </b-col>
                    <b-col md="2">
                      <g-field
                        :value.sync="selectedItem.discountPercentage"
                        :disabled="selectedItem.discountValue > 0"
                        placeholder="percentage"
                        name="percentage"
                        type="number"
                        rules="min_value:0|max_value:100"
                        label-text="discountPercentage"
                        @change="() => {
                          selectedItem.discountValue = 0
                        }"
                      >
                        <template #append>
                          <b-input-group-text> % </b-input-group-text>
                        </template>
                      </g-field>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-tab>
        <!-- <div class="text-center">
      <b-button-group class="mt-2">
        <b-button @click="tabIndex--">Previous</b-button>
        <b-button @click="tabIndex++">Next</b-button>
      </b-button-group>

      <div class="text-muted">Current Tab: {{ tabIndex }}</div>
    </div> -->
      </b-tabs>
      <b-row>
        <b-col cols="12">
          <hr>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            type="submit"
            variant="relief-primary"
            :disabled="codeLengthNotValid === true"
            data-action-type="save"
          >
            <feather-icon
              :icon="selectedItem.id > 0 ?'EditIcon': 'SaveIcon'"
              class="mr-50"
            />
            {{ selectedItem.id > 0 ? $t('edit') : $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script
  scr="https://cdnjs.cloudflare.com/ajax/libs/intro.js/5.1.0/introjs.min.css"
></script>
<script>
import GTable from '@/pages/Shared/Table.vue';
import { cementTypesOptions, offersAndDiscounts, carConditions } from '@/libs/acl/Lookups';
import introJs from 'intro.js/intro';
import 'intro.js/introjs-rtl.css';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    GTable,
    SomeComponent: () => {
      // return new Promise((resolve, reject) => {
      introJs().onchange(function(targetElement) {
      }).start();
      // })
    },
    FeatherIcon
  },
  props: ['id'],
  data() {
    return {
      disableSubUnit: false,
      offersAndDiscountsSelected: null,
      matrialTypes: cementTypesOptions,
      offersAndDiscounts: offersAndDiscounts,
      myWindow: '',
      tabIndex: 0,
      isValid: true,
      buttonType: '',
      sortBy: 'id',
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      perPage: 25,
      filterOn: [],
      isSortDirDesc: false,
      sortDirection: 'asc',
      searchQuery: '',
      codeLengthNotValid: false,
      carConditions,
      carColors: [],
      oldStoreOpeningBalance: 0,
      oldStoreCurrentBalance: 0,
      filter: {
        code: '',
        arabicNamePart: '',
        englishNamePart: '',
        OrderClause: '',
        type: '',
        storeId: null
      },
      selectedItem: {
        id: 0,
        code: '',
        arabicName: '',
        englishName: '',
        notes: '',
        isScalesItem: false,
        categoryId: null,
        mainUnitId: null,
        mainUnitBarcode: '',
        salesPrice: 0,
        minSalesPrice: 0,
        maxSalesPrice: 0,
        isTaxable: true,
        isStoreItem: true,
        subUnits: [],
        stores: [],
        components: [],
        additions: [],
        sales: true,
        purchases: true,
        returnSales: true,
        returnPurch: true,
        quotation: true,
        salesOrder: true,
        purchOrder: true,
        storageTransfer: true,
        additionOrder: true,
        exchangeOrder: true,
        damageAdjustment: true,
        itemTax : 0,
        isUsed: false,
        discountPercentage: 0,
        discountValue: 0,
        discountAppliedFromDate:  this.today,
        discountAppliedToDate:  this.today,
        offerQuantity: 0,
        offerFreeQuantity: 0,
        offerAppliedFromDate:  this.today,
        offerAppliedToDate:  this.today,
        type: 'rawMaterial'
      },
      // this obj that be added to table
      store: {
        itemId: 0,
        storeId: null,
        itemPlace: '',
        openingBalance: 0,
        currentBalance: 0,
        minimumLimit: 0,
        reorderLimit: 0,
        costPrice: 0,
        isActive: true,
      },
      unitItem: {
        itemId: 0,
        unitId: null,
        unitArabicName: '',
        unitEnglishName: '',
        conversionRate: 0,
        barcode: '',
        salesPrice: 0,
        minSalesPrice: 0,
        maxSalesPrice: 0
      },
      componentItem: {
        itemId: 0,
        componentId: null,
        requiredQuantity: 0,
        notes: '',
      },
      storesData: [], // from db
      categories: [],
      units: [],
      itemsData: [],
      url: '',
      totalCurrentBalance: 0,
      totalOpeningBalance: 0,
      cementTypes: [],
      isPerfectly: false,
      validateItem: false,
      validatePurchItem: false,
      validateStorageItem: false
    };
  },
  watch: {
    'offersAndDiscountsSelected'(newVal) {
      if (newVal === 'offers') {
        this.selectedItem.discountPercentage = 0;
        this.selectedItem.discountValue = 0;
      }
      if (newVal === 'discounts') {
        this.selectedItem.offerQuantity = 0;
        this.selectedItem.offerFreeQuantity = 0;
      }
    }
  },
  computed: {
    totalCosts() {
      return this.selectedItem.components.reduce((sum, item) => {
        sum += parseFloat(item.totalCostPrice);
        return sum;
      }, 0);
    },
    dataTypesOptions() {
      return this.matrialTypes.map((item) => {
        return {
          text: this.isRight ? item.arabicName : item.englishName,
          value: item.id,
        }
      });
    },
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
          // style: { width: '20%' },
        },
        {
          key: 'mainUnitId',
          label: this.$t('unit'),
          sortable: true,
        },
        { key: 'mainUnitBarcode', label: this.$t('barcode'), sortable: true },
        { key: 'actions' },
      ];
    },
    serialsTableColumns() {
      return [
        { key: 'serial', label: this.$t('serial'), sortable: true },
        {
          key: 'color',
          label: this.$t('color'),
          sortable: true,
          formatter: (key, value, item) => {
              return item.extraProperties.colorName
        }
      },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
        formatter: (key, value, item) => {
          if (item.extraProperties.carStatus === 'New' || item.extraProperties.carStatus === '1') {
            return item.status = 'جديد';
              }
              if (item.extraProperties.carStatus === 'Used' || item.extraProperties.carStatus === '2') {
                return item.status = 'مستعمل';
              }
              if (item.extraProperties.carStatus === 'Hulk' || item.extraProperties.carStatus === '3') {
                return item.status = 'متهالك';
              }
        }
      }
      ];
    },
    storeTableColumns() {
      return [
        {
          key: this.isRight ? 'storeArabicName' : 'storeEnglishName',
          label: this.$t('store'),
          sortable: false,
        },
        { key: 'itemPlace', label: this.$t('itemPlace'), sortable: false },
        {
          key: 'openingBalance',
          label: this.$t('openingBalance'),
          sortable: true,
          type: 'number',
          footer: () => this.fraction(this.totalOpeningBalance),
          tdClass: this.currentBranch.activityType !== 'carShowroom' && !this.profile.isInvoicePlan ? '' : 'd-none',
          thClass: this.currentBranch.activityType !== 'carShowroom' && !this.profile.isInvoicePlan ? '' : 'd-none',
        },
        {
          key: 'currentBalance',
          label: this.$t('currentBalance'),
          sortable: false,
          type: 'number',
          footer: () => this.fraction(this.totalCurrentBalance),
        },
        {
          key: 'minimumLimit',
          label: this.$t('minimumLimit'),
          sortable: false,
          type: 'number'
        },
        {
          key: 'costPrice',
          label: this.$t('costPrice'),
          sortable: false,
          tdClass: !this.profile.isInvoicePlan ? '' : 'd-none',
          thClass: !this.profile.isInvoicePlan ? '' : 'd-none',
          type: 'number'
        },
        { key: 'actions' },
      ];
    },
    unitTableColumns() {
      return [
        {
          key: this.isRight ? 'unitArabicName' : 'unitEnglishName',
          label: this.$t('unit'),
          sortable: true,
        },
        {
          key: 'conversionRate',
          label: this.$t('conversionRate'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'barcode',
          label: this.$t('barcode'),
          sortable: true,
        },
        {
          key: 'minSalesPrice',
          label: this.$t('minSalesPrice'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'salesPrice',
          label: this.$t('salesPrice'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'maxSalesPrice',
          label: this.$t('maxSalesPrice'),
          sortable: true,
          type: 'number'
        },
        { key: 'actions' },
      ];
    },
    additionsTableColumns() {
      return [
        {
          key: this.isRight ? 'additionArabicName' : 'additionEnglishName',
          label: this.$t('additions'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
    componentsTableColumns() {
      return [
        {
          key: this.isRight ? 'componentArabicName' : 'componentEnglishName',
          label: this.$t('components'),
        },
        {
          key: 'mainUnitId',
          label: this.$t('unit'),
          sortable: true,
        },
        {
          key: 'quantity',
          label: this.$t('requiredQuantity'),
          type: 'number'
        },
        {
          key: 'costPrice',
          label: this.$t('costPrice'),
          type: 'number',
        },
        {
          key: 'totalCostPrice',
          label: this.$t('total'),
          type: 'number',
        },
        {
          key: 'notes',
          label: this.$t('notes'),
        },
        { key: 'actions' },
      ];
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {
    this.selectedItem.discountAppliedFromDate = this.today;
    this.selectedItem.discountAppliedToDate = this.today;
    this.selectedItem.offerAppliedFromDate = this.today;
    this.selectedItem.offerAppliedToDate = this.today;
    // this.selectedItem.cementType = 'rawMaterial'
    if (this.$route.query) {
      this.selectedItem.mainUnitBarcode = this.$route.query.barCode;
    }
    // this.selectedItem.type = 'rawMaterial';
    if (this.currentBranch.activityType === 'concrete') {
      this.getCementTypes();
    }
    if (this.id > 0) {
      this.getData();
    } else {
      this.url = 'item_default_image.jpg';
    }
  },
  methods: {
    changeType(v) {
      if (v === 'perfectlyMade') {
        this.isPerfectly = true;
      } else {
        this.isPerfectly = false;
        this.selectedItem.cementTypeId = null
        this.selectedItem.cementContent = null
        this.selectedItem.cementStrength = null
        this.selectedItem.gravelSize = null
      }
    },
    addComponentMFun() {
      if (this.filter.storeId > 0) {
        this.buttonType = 'component';
        this.$refs['search-modal'].show();
      } else {
        this.doneAlert({ text: this.$t('selectStore'), type: 'warning' });
        return
      }
    },
    getCementTypes() {
      this.get({ url: 'CementTypes' }).then((data) => {
        this.cementTypes = data;
      }).catch(() => {
        this.cementTypes = [];
      })
    },
    allowedUnits(item, option) {
      return !this.selectedItem.components.filter((i) => i !== item)
        .some((i) => i.unitId === option.unitId && i.itemId === option.itemId);
    },
    getValidateItem(storeId) {
      this.get({ url: `Items/item-transaction-line?itemId=${this.id}&storeId=${storeId}` }).then((data) => {
        this.validateItem = data.isItemHasTransaction;
        this.validateStorageItem = data.isItemHasStorageTransaction
        this.validatePurchItem = data.isItemHasPurchaseTransaction;
      });
    },
    nextTab() {
      this.tabIndex = 1;
    },
    goToStep(num) {
      introJs()
        .setOptions({
          nextLabel: this.$t('next'),
          prevLabel: this.$t('prev'),
          doneLabel: this.$t('finish'),
          showBullets: false
        }).goToStepNumber(num).start();
    },
    genrateCode() {
      this.get({ url: 'Items/generate-barcode' }).then((data) => {
        this.selectedItem.mainUnitBarcode = data.toString();
      });
    },
    loadData() {
      this.get({ url: 'Aggregates/Items' }).then((data) => {
        this.units = data.units;
        this.storesData = data.stores;
        this.categories = data.itemCategories;
      //   this.get({ url: 'CarColors' }).then((data) => {
      //   this.carColors = data;
      // });
      })
    },
    getData() {
      this.get({ url: 'Aggregates/Items' }).then((data) => {
        this.units = data.units;
        this.storesData = data.stores;
        this.categories = data.itemCategories;
      }).then(() => {
        this.get({ url: 'Items', id: this.id }).then((data) => {
        this.selectedItem = data;
        data.stores.forEach(item => {
          item.id = item.storeId
        })
        if (this.selectedItem.offerQuantity > 0 || this.selectedItem.offerFreeQuantity > 0) {
          this.offersAndDiscountsSelected = 'offers';
        }
        if (this.selectedItem.discountValue > 0 || this.selectedItem.discountPercentage > 0) {
          this.offersAndDiscountsSelected = 'discounts';
        }
        // this.changeType(this.selectedItem.type)
        this.url = this.selectedItem.imageUrl
          ? `${this.baseUrl}${this.selectedItem.imageUrl}`
          : 'item_default_image.jpg';
        this.selectedItem.stores.forEach((item) => {
          this.totalOpeningBalance += item.openingBalance;
          this.totalCurrentBalance += item.currentBalance;
        });
        if (this.selectedItem.type === 'perfectlyMade') {
        this.isPerfectly = true;
      } else {
        this.isPerfectly = false;
      }
      this.filter.storeId = data.componentsStoreId ?  data.componentsStoreId : null
        this.selectedItem.components.forEach(ele => {
        // const store = this.selectedItem.stores.find((val) => val.storeId === this.currentBranch.defaultStoreId) || { currentBalance: 0, costprice: 0 };
        const res = this.units.find((v) => v.id === ele.unitId);
          ele.units = ele.subUnits;
          ele.unitArabicName = res.arabicName;
          ele.unitEnglishName = res.englishName;
          // ele.units.push({
          //     unitId: ele.unitId,
          //     unitArabicName: res.arabicName,
          //     unitEnglishName: res.englishName,
          //     salesPrice: res.salesPrice,
          //     minSalesPrice: res.minSalesPrice,
          //     maxSalesPrice: res.maxSalesPrice,
          //     barcode: res.mainUnitBarcode,
          //     conversionRate: 1,
          //     costPrice: store.costPrice,
          //     isMain: true,
          //   });
        });
        const subUnitUsed = this.selectedItem.subUnits.filter(x=>x.isUsed==true)
        if (subUnitUsed.length > 0) {
          this.disableSubUnit = true;
        }
      });
      })
      },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.base64Image = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    loadItems() {
      this.get({ url: 'Items' }).then((data) => {
        this.itemsData = data.data;
      });
    },
    addStoreToItem() {
      let msg = '';
      if (this.store.openingBalance < 0) msg = 'openingBalanceMustBeMoreThanZero';
      else if (this.store.minimumLimit < 0) msg = 'minimumLimitMustBeMoreThanZero';
      else if (this.store.costPrice < 0) msg = 'costPriceMustBeMoreThanZero';
      if (msg) {
         this.doneAlert({
              text: this.$t(msg),
              type: 'error',
            });
            return;
      }
      if (this.store.id) {
        if (this.selectedItem.stores.length > 0) {
          if (this.selectedItem.stores.find((s) => s.storeId === this.store.id) && !this.store.storeId) {
            this.doneAlert({
              text: this.$t('canNotRepeatTheSameStore'),
              type: 'error',
            });
            return;
          }
          if (this.selectedItem.stores.find((s) => s.storeId === this.store.oldId) && this.store.storeId) {
            const index = this.selectedItem.stores.findIndex((s) => s.storeId === this.store.oldId);
            // delete this.store.id;
            const resStore = this.storesData.find((v) => v.id === this.store.id);
            this.store.storeArabicName = resStore.arabicName;
            this.store.storeEnglishName = resStore.englishName;
            this.store.arabicName = resStore.arabicName;
            this.store.englishName = resStore.englishName;
            this.store.storeId = resStore.id;
            this.store.id = resStore.id;
            if (this.validateStorageItem === false) {
              this.store.currentBalance = this.store.openingBalance;
            }
            this.$set(this.selectedItem.stores, index, this.store)
            this.totalOpeningBalance -= this.oldStoreOpeningBalance;
            this.totalCurrentBalance -= this.oldStoreCurrentBalance; // added
            this.totalOpeningBalance += parseFloat(this.store.openingBalance);
            this.store.currentBalance = this.store.currentBalance // added
            this.totalCurrentBalance += parseFloat(this.store.currentBalance); // added
            this.initStore();
            this.validateItem = false;
            this.validatePurchItem = false;
            this.validateStorageItem = false;
          return;
          }
        }
        if (this.store.id !== 0 && !this.store.storeId) {
          const res = this.storesData.find((v) => v.id === this.store.id);
          this.store.storeArabicName = res.arabicName;
          this.store.storeEnglishName = res.englishName;
          this.store.storeId = res.id;
          this.store.id = res.id;
        }
        this.store.currentBalance = this.store.openingBalance;
        // delete this.store.id;
        this.selectedItem.stores.push({
          ...this.store,
          openingBalance: this.store.openingBalance,
          currentBalance: this.store.openingBalance,
        });
        this.totalOpeningBalance += parseFloat(this.store.openingBalance);
        this.totalCurrentBalance += parseFloat(this.store.currentBalance);
        this.initStore();
      }
    },
    addAdditionToItem(item) {
      let errorMsg = '';
      if (item.id.toString() === this.id) errorMsg = 'canNotUseParentItem';
      else if (this.selectedItem.additions.find((o1) => o1.id === item.id)) errorMsg = 'canNotRepeatTheSameItem';
      if (errorMsg) {
         this.doneAlert({
          text: this.$t(errorMsg),
          type: 'error',
        });
      } else {
        this.selectedItem.additions.push({
          itemId: this.id ? Number(this.id) : 0,
          additionId: item.id,
          additionArabicName: item.arabicName,
          additionEnglishName: item.englishName,
        });
      }
    },
    addUnitToItem() {
      if (!this.unitItem.unitId) return true;
      let errorMsg = '';
      if (this.selectedItem.subUnits.find((v) => v.unitId === this.unitItem.unitId)) errorMsg = this.$t('canNotRepeatTheSameUnit');
      if (this.selectedItem.subUnits.find((v) => v.barcode === this.unitItem.barcode.replace(/\s/g, ''))) errorMsg = this.$t('canNotRepeatTheSameBarcode');
      else if (this.unitItem.unitId === this.selectedItem.mainUnitId) errorMsg = this.$t('thisUnitIsUsedAsMainUnit');
      else if (this.unitItem.conversionRate <= 1) errorMsg = this.$t('conversationRateMustBeMoreThanOne');
      else if (!this.unitItem.barcode) errorMsg = this.$t('barCodeIsRequired');

      if (errorMsg) {
        this.doneAlert({
          text: errorMsg,
          type: 'error',
        });
      } else {
        const res = this.units.find((v) => v.id === this.unitItem.unitId);
        this.unitItem.unitArabicName = res.arabicName;
        this.unitItem.unitEnglishName = res.englishName;
        this.unitItem.itemId = this.id > 0 ? this.id : 0;
        this.unitItem.salesPrice = this.unitItem.salesPrice === 0 ? this.unitItem.conversionRate * this.selectedItem.salesPrice : this.unitItem.salesPrice;
        this.selectedItem.subUnits.push({ ...this.unitItem });
      }
      return false;
    },
    addComponentToItem(item) {
       let errorMsg = '';
      if (item.id.toString() === this.id) errorMsg = 'canNotUseParentItem';
      else if (this.selectedItem.components.find((v) => v.componentId === item.id)) errorMsg = 'canNotRepeatTheSameItem';
      if (errorMsg) {
         this.doneAlert({
          text: this.$t(errorMsg),
          type: 'error',
        });
      } else {
        var itemArr = [];
        item.quantity = 1
        const store = item.stores.find((val) => val.storeId === this.filter.storeId) || { currentBalance: 0, costprice: 0 };
            item.currentBalance = store.currentBalance;
            item.costPrice = store.costPrice || 0;
            item.units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
        const units = item.subUnits;
        units.push({
          unitId: item.mainUnitId,
          unitArabicName: item.mainUnitArabicName,
          unitEnglishName: item.mainUnitEnglishName,
          salesPrice: item.salesPrice,
          minSalesPrice: item.minSalesPrice,
          conversionRate: 1,
          maxSalesPrice: item.maxSalesPrice,
          barcode: item.mainUnitBarcode,
          isMain: true,
        });
        item.units = units
        itemArr.push({
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitArabicName: item.mainUnitArabicName,
            unitEnglishName: item.mainUnitEnglishName,
            unitId: item.matchedUnitId || item.mainUnitId,
            code: item.code,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            id: item.id,
            quantity: 1,
            costPrice: store.costPrice || 0,
            convertedBalance: item.currentBalance / (units.find((u) => u.unitId === item.mainUnitId).conversionRate || 1),
            price: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            mainPrice: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: 0,
            net: 0,
            stores: item.stores,
            isTaxable: item.isTaxable,
            barCode: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode,
            matchedUnitId: item.matchedUnitId,
            units,
          })
          item = itemArr[0]
        this.selectedItem.components.push({
          ...this.componentItem,
          ...item,
          componentId: item.id,
          componentArabicName: item.itemArabicName,
          componentEnglishName: item.itemEnglishName,
          units: item.units,
          storeId: this.filter.storeId,
          unitId: item.unitId,
          costPrice: item.costPrice,
          totalCostPrice: this.fraction(item.quantity * item.costPrice),
          requiredQuantity: 1
        });
      }
    },
    checkIfExistInUnitItems() {
      if (
        this.selectedItem.subUnits.find((v) => v.unitId === this.selectedItem.mainUnitId)
      ) {
        this.doneAlert({
          text: this.$t('thisUnitIsUsedAsSubUnit'),
          type: 'error',
        });
        this.selectedItem.mainUnitId = 0;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshTable() {
      this.$refs.itemstable.refresh();
    },
    refreshItems() {
      this.refreshTable();
    },
    validateBalancedItem() {
      this.doneAlert({ text: this.$t('maxLenghtIsFive'), type: 'error' });
      this.codeLengthNotValid = true
    },
    checkMainUnit() {
    if (this.selectedItem.mainUnitId === null) {
        this.doneAlert({ text: this.$t('mainUnitIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    checkBarCode() {
      if (!this.selectedItem.mainUnitBarcode) {
        this.doneAlert({ text: this.$t('barCodeMainUnitIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    checkStores() {
      if (this.selectedItem.stores.length === 0) {
        this.doneAlert({ text: this.$t('mustAddOneStoreAtLeast'), type: 'error' });
        return false;
      }
      return true;
    },
    checkCategories() {
      if (this.selectedItem.categoryId === null) {
        this.doneAlert({ text: this.$t('categoryIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    save() {
      if (this.selectedItem.discountValue === 0 && this.selectedItem.discountPercentage === 0) {
        this.selectedItem.discountAppliedFromDate = null;
        this.selectedItem.discountAppliedToDate = null;
      }
      this.checkIfExistInUnitItems();
      if (!this.checkCategories()) return;
      if (!this.checkMainUnit()) return;
      if (!this.checkBarCode()) return;
      if (!this.checkStores()) return;
      if (this.selectedItem.isScalesItem === true) {
        this.selectedItem.mainUnitBarcode = this.selectedItem.mainUnitBarcode.toString();
      }
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'Items',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.$router.push({ name: 'items' });
          });
      } else {
        this.create({
          url: 'Items',
          data: this.selectedItem,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.$router.push({ name: 'items' });
          });
      }
    },
    removeStoreFromTable(store) {
      this.selectedItem.stores = this.selectedItem.stores.filter((item) => item !== store);
      this.totalOpeningBalance -= parseFloat(store.openingBalance);
      this.totalCurrentBalance -= parseFloat(store.currentBalance);
    },
    removeUnitFromTable(unit) {
      this.selectedItem.subUnits = this.selectedItem.subUnits.filter((item) => item !== unit);
    },
    removeAdditionFromTable(addition) {
      this.selectedItem.additions = this.selectedItem.additions.filter((item) => item !== addition);
    },
    removeComponentFromTable(component) {
      this.selectedItem.components = this.selectedItem.components.filter((item) => item !== component);
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
      if (this.currentBranch.activityType === 'concrete') {
        this.filter.type = 'rawMaterial'
      }
      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `items${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.forEach((item) => {
            // adapt item object to set prices with each unit based on conversion rate and selected store
            item.quantity = 0;
            const store = item.stores.find((val) => val.storeId === this.currentBranch.defaultStoreId) || { currentBalance: 0, costprice: 0 };
            item.currentBalance = store.currentBalance;
            item.costPrice = store.costPrice || 0;
            item.units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
            item.unitId = item.matchedUnitId || item.mainUnitId;
            item.barCode = item.matchedUnitId ? item.units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode;
            item.units.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
              salesPrice: item.salesPrice,
              minSalesPrice: item.minSalesPrice,
              maxSalesPrice: item.maxSalesPrice,
              barcode: item.mainUnitBarcode,
              conversionRate: 1,
              itemId: item.id,
              costPrice: store.costPrice,
              isMain: true,
            });
            const existItem = this.selectedItem.components.find((val) => val.id === item.id && val.unitId === item.mainUnitId);
            if (existItem) {
              item.quantity = existItem.quantity;
              item.unitId = existItem.unitId;
            }
            // return false;
          });
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    closeModal() {
      this.$refs['search-modal'].hide();
    },
    editStore(item) {
      if (this.id) {
        this.getValidateItem(item.storeId)
      }
      item.id = item.storeId
      this.store = { ...item }
      this.store.id = item.storeId;
      this.store.oldId = item.storeId;
      this.oldStoreOpeningBalance = this.store.openingBalance;
      this.oldStoreCurrentBalance = this.store.currentBalance;
    },
    initStore() {
      this.store = {
        itemId: 0,
        storeId: null,
        itemPlace: '',
        openingBalance: 0,
        currentBalance: 0,
        minimumLimit: 0,
        reorderLimit: 0,
        costPrice: 0,
        isActive: true,
      }
    }
  },
};
</script>

<style>
.resize_img {
  width: 10%;
  border-radius: 10%;
  margin-left: 50%;
  margin-right: auto;
}
</style>
